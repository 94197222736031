.component__footer {
    width: 100%;
    height: fit-content;
    background: #11002F;
    padding-left: 310px;
    padding-right: 310px;
    padding-top: 60px;
    padding-bottom: 24px;

    &__gridbox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: baseline;
            img {
                display: block;
                margin-left: 0;
                margin-right: auto;
            }

            p, a {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #FFFFFF;
                text-align: left;
                margin-bottom: 21px;
                &:first-child{
                    margin-top: 21px;
                }
            }

            h1 {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
                color: #FFFFFF;
                margin-bottom: 31px;
                text-align: left;
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: white;
                text-align: left;
                margin-bottom: 16px;

                img {
                    margin-right: 20px;
                }
            }

            input {
                width: 270px;
                height: 44px;
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                border: none;
                outline: none;
                margin-bottom: 16px;
                padding: 20px;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #564B68;

                display: block;
                margin-left: 0;
                margin-right: auto;
                margin-bottom: 20px;

                &::placeholder {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #564B68;
                }
            }
        }
    }

    &__copyright {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #FFFFFF;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding-top: 61px;

        span {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            img {
                margin-right: 30px;
                cursor: pointer;
                filter: brightness(0) invert(1);
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .component__footer {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 1230px) {
    .component__footer__gridbox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 46px 1fr;
        grid-column-gap: 129px;
        grid-row-gap: 40px;

        #f-item1 {
            grid-area: 1 / 1 / 2 / 2;
        }

        #f-item2 {
            grid-area: 2 / 1 / 3 / 2;
        }

        #f-item3 {
            grid-area: 2 / 2 / 3 / 3;
        }
    }
}

@media screen and (max-width: 735px) {
    .component__footer__gridbox div input {
        width: 200px;
    }
}

@media screen and (max-width: 670px) {
    .component__footer__gridbox {
        display: flex;
        flex-direction: column;
    }

    .component__footer__gridbox div input {
        width: 360px;
    }

    .component__footer__copyright {
        flex-direction: column;

        span {
            margin-top: 21px;
            justify-content: flex-end;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

@media screen and (max-width: 574px) {
    .component__footer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .component__footer__copyright p {
        text-align: left;
    }
}

@media screen and (max-width: 388px) {
    .component__footer__gridbox div input {
        width: 320px;
    }
}