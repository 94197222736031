.route__career {
    background: #F8F5F3;

    &__box {
        width: 700px;
        margin-top: 81px;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &__content {
            background: #FFFFFF;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            width: 700px;
            height: fit-content;
            padding: 30px;
            padding-top: 40px;
            padding-bottom: 40px;
            animation: animateModal .3s linear;

            #desc{
                text-align: center !important;
            }

            p{
                text-align: left !important;
                li{
                    margin-bottom: 10px;
                }
            }

            div{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                button {
                    width: 160px;
                    height: 53px;
                    left: 1040px;
                    top: 670px;
                    background: #5956E9;
                    border-radius: 5px;
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 25px;
                    color: #FFFFFF;
                    border: none;
                    display: block;
                    cursor: pointer;
                }
            }

            h1 {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 700;
                font-size: 40px !important;
                line-height: 46px;
                text-align: center;
                color: #11002F;
            }
        }

        h1 {
            margin-bottom: 20px;
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            line-height: 69px;
            text-align: center;
            color: #11002F;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #564B68;
            margin-bottom: 40px;
            text-align: center;
        }

        &__tabs {
            display: grid;
            grid-template-columns: 1fr;
            place-items: center;

            &__tab {
                width: 100%;
                height: 82px;
                margin-bottom: 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 16px;
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 10px;

                strong {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 140%;
                    display: flex;
                    align-items: center;
                    color: #11002F;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    text-align: left;
                    margin-bottom: 0;
                }

                #btnApply {
                    width: 160px;
                    height: 51px;
                    background: #5956E9;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 25px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.component__formModal {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: animateModal .3s linear;

    @keyframes animateModal {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #FFFFFF;
    }

    .finish {
        animation: animateModal .3s linear;
    }

    &__box {
        display: grid;
        grid-template-columns: 1fr;
        background: #11002F;
        border-radius: 10px;
        padding: 50px;
        gap: 22px;
        width: 842px;
        z-index: 1000;

        #close {
            display: block;
            margin-left: auto;
            margin-right: 0;
            cursor: pointer;
        }

        #con {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            margin-top: 24px;
            margin-bottom: 30px;
        }

        strong {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: #FFFFFF;
            margin-left: 0;
            margin-right: auto;
        }

        button {
            width: 292px;
            height: 53px;
            left: 1040px;
            top: 670px;
            background: #5956E9;
            border-radius: 5px;
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 25px;
            color: #FFFFFF;
            border: none;
            margin-right: 0;
            margin-left: auto;
            cursor: pointer;
        }

        #file {
            #fileinput {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                div {
                    background: #251939;
                    border: 1px solid #382854;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    outline: none;
                    width: calc(100% - 150px);
                    height: 50px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #AFAFAF;
                    margin-top: 12px;
                    padding-left: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: baseline;
                }

                input {
                    display: none;
                }

                button {
                    background: #251939;
                    border: 1px solid #382854;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    width: 137px;
                    height: 50px;
                    margin-top: 12px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #AFAFAF;
                    cursor: pointer;
                }
            }
        }

        #row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 22px;

            div {
                display: flex;
                flex-direction: column;
                justify-content: baseline;
                align-items: baseline;

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #AFAFAF;
                }

                input {
                    background: #251939;
                    border: 1px solid #382854;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    outline: none;
                    width: 360px;
                    height: 50px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #AFAFAF;
                    margin-top: 12px;
                    padding-left: 5px;
                }
            }
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
            color: #AFAFAF;
        }

        textarea {
            width: 100%;
            height: 116px;
            left: 589px;
            top: 524px;
            background: #251939;
            border: 1px solid #382854;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            outline: none;
            margin-top: 12px;
            padding: 5px;
            font-size: 16px;
            line-height: 19px;
            color: #AFAFAF;
        }
    }
}

@media screen and (max-width: 850px) {
    .route__career__box {
        width: 95%;
    }

    .component__formModal__box {
        width: 95%;
    }

    .component__formModal__box #row div input {
        width: 100%;
    }

    .route__career__box__content {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 449px) {
    .route__career__box__tabs__tab {
        flex-direction: column;
        height: fit-content;
        align-items: center;
        justify-content: center;
    }

    .route__career__box__tabs__tab p {
        text-align: center;
    }

    .route__career__box__tabs__tab #btnApply {
        margin-top: 16px;
    }

    .component__formModal__box #row {
        grid-template-columns: 1fr;
    }

    .component__formModal__box button {
        width: 100%;
    }

    .component__formModal__box {
        padding: 20px;
    }
    .route__career__box__content div{
        flex-direction: column;
        gap: 10px;
    }
    .route__career__box__content div button{
        width: 100%;
    }
}