*{
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
 }
 
 html,body, #root {
  max-width: 1927px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
 }
 
 #root{
  overflow: auto;
 }