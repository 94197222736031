.component__button{
    background: #5956E9;
    color: white;
    border: none;
    border-radius: 5px;
    transition: 400ms;
    font-family: 'Ubuntu';
    cursor: pointer;
    &:hover{
        background: #7F7DFE;
    }
}