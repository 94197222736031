.animate{
    animation: animate-scale-lead 1s linear;

    @keyframes animate-scale-lead{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
}

.route__companies {
    width: 100%;

    &__sectionOne {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 70px;

        h1 {
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 57px;
            color: #11002F;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #564B68;
            width: 531px;
            margin-top: 20px;
        }
    }

    &__sectionTwo {
        padding-top: 103px;
        padding-left: 310px;
        padding-right: 310px;
        padding-bottom: 57px;
        background: #11002F;
        margin-top: 50px;

        &__gridbox {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 53px;
            animation: animateScaleDev 0.5s linear;
            @keyframes animateScaleDev{
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }

            &__left {
                h1 {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 46px;
                    color: #FFFFFF;
                    margin-bottom: 28px;
                    text-align: left;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFAF5;
                    text-align: left;
                }

                &__cols {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    gap: 32px;
                    margin-top: 28px;

                    div {
                        padding-top: 16px;
                        border-top: 1px solid white;

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFAF5;
                            text-align: left;
                        }

                        #findmoreButton {
                            margin-top: 61px;
                            width: 100%;
                            height: 57px;
                            font-size: 22px;
                        }
                    }
                }
            }

            &__right {
                img {
                    width: 100%;
                }
            }
        }
    }

    &__sectionThree {
        padding-top: 80px;
        padding-left: 310px;
        padding-right: 310px;
        position: relative;
        padding-bottom: 70px;

        #string-two {
            position: absolute;
            bottom: 0;
            right: 5%;
            width: 291px;
            height: 309px;
        }

        &__gridboxOne {
            display: grid;
            grid-template-columns: 60% 40%;
            gap: 52px;
            place-items: center;

            div {
                img {
                    width: 100%;
                }

                h1 {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 46px;
                    color: #11002F;
                    text-align: left;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #564B68;
                    text-align: left;
                    margin-top: 28px;
                }

                #findhowButton {
                    margin-top: 36px;
                    width: 304px;
                    height: 57px;
                    font-size: 22px;
                }
            }
        }

        &__gridboxTwo {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 53px;
            padding-top: 80px;

            &__left {
                h1 {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 46px;
                    color: #11002F;
                    margin-bottom: 28px;
                    text-align: left;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #564B68;
                    text-align: left;
                }

                &__cols {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    gap: 32px;
                    margin-top: 28px;

                    div {
                        padding-top: 16px;
                        border-top: 1px solid #564B68;

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #564B68;
                            text-align: left;
                        }

                        #scalepickButton {
                            margin-top: 61px;
                            width: 100%;
                            height: 57px;
                            font-size: 22px;
                        }
                    }
                }
            }

            &__right {
                img {
                    width: 100%;
                }
            }
        }
    }

    &__sectionFour {
        padding-left: 310px;
        padding-right: 310px;
        padding-bottom: 70px;
        background: #F8F5F3;

        &__gridbox {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 53px;
            padding-top: 80px;

            &__left {
                h1 {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 46px;
                    color: #11002F;
                    margin-bottom: 28px;
                    text-align: left;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #564B68;
                    text-align: left;
                }

                &__cols {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    gap: 32px;
                    margin-top: 28px;

                    div {
                        padding-top: 16px;
                        border-top: 1px solid #192327;

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #564B68;
                            text-align: left;
                        }

                        #scalepickButton {
                            margin-top: 61px;
                            width: 100%;
                            height: 57px;
                            font-size: 22px;
                        }
                    }
                }
            }

            &__right {
                img {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1614px){
    .route__companies__sectionTwo{
        padding-left: 100px;
        padding-right: 100px;
    }
    .route__companies__sectionThree{
        padding-left: 100px;
        padding-right: 100px;
    }
    .route__companies__sectionThree #string-two{
        right: 0;
    }
    .route__companies__sectionFour{
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 1236px){
    .route__companies__sectionTwo__gridbox{
        grid-template-columns: 1fr;
    }
    .route__companies__sectionThree__gridboxOne{
        grid-template-columns: 1fr;
        #g1-1{
            order: 2;
        }
        #g1-2{
            order: 1;
        }
    }
    .route__companies__sectionThree__gridboxTwo{
        grid-template-columns: 1fr;
    }
    .route__companies__sectionFour__gridbox{
        grid-template-columns: 1fr;
    }
    .route__companies__sectionFour__gridbox__right{
        order: 2;
    }
    .route__companies__sectionFour__gridbox__left{
        order: 1;
    }
}

@media screen and (max-width: 708px){
    .route__companies__sectionThree #string-two{
        bottom: -2%;
    }
    .route__companies__sectionTwo{
        padding-left: 30px;
        padding-right: 77px;
    }
    .route__companies__sectionThree{
        padding-left: 30px;
    }
    .route__companies__sectionFour{
        padding-left: 30px;
    }
    .route__companies__sectionThree__gridboxOne #g1-1{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .route__home__sectionOne__left #bars{
        width: fit-content;
    }
}

@media screen and (max-width: 614px){
    .route__companies__sectionOne h1{
        font-size: 36px;
    }
    .route__companies__sectionTwo__gridbox__left__cols{
        grid-template-columns: 1fr;
    }
    .route__companies__sectionThree__gridboxTwo__left__cols{
        grid-template-columns: 1fr;
    }
    .route__companies__sectionFour__gridbox__left__cols{
        grid-template-columns: 1fr;
    }
    .route__companies__sectionOne p{
        width: 100%;
    }
    .route__companies__sectionTwo{
        padding-right: 20px;
        padding-left: 20px;
    }
    .route__companies__sectionThree{
        padding-left: 20px;
        padding-right: 20px;
    }
    .route__companies__sectionFour{
        padding-left: 20px;
        padding-right: 20px;
    }
    .route__companies__sectionThree #string-two{
        width: 120px;
        height: 120px;
        bottom: 0;
    }
    .route__companies__sectionTwo__gridbox__left__cols div #findmoreButton{
        width: 258px;
        height: 53px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .route__companies__sectionThree__gridboxOne div #findhowButton{
        width: 100%;
    }
    .route__companies__sectionThree__gridboxTwo__left__cols div #scalepickButton{
        width: 258px;
        height: 53px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}