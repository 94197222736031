.route__aboutus {
    width: 100%;

    &__sectionOne {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 71px;
        padding-bottom: 50px;

        animation: animate-secOneAbout 0.5s linear;

        @keyframes animate-secOneAbout{
            0%{
                max-height: 0;
            }
            100%{
                max-height: 1000px;
            }
        }

        h1 {
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            line-height: 69px;
            text-align: center;
            color: #11002F;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #564B68;
            width: 531px;
            margin-top: 30px;
        }
    }

    &__sectionTwo {
        background: #11002F;
        padding-left: 310px;
        padding-right: 310px;
        padding-top: 60px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: baseline;

        #line{
            width: 182px;
            border: 1px solid white;
        }

        h1 {
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 46px;
            color: #FFFFFF;
            margin-bottom: 28px;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FFFAF5;
            width: 630px;
            text-align: left;
            padding-top: 29px;
        }
    }

    &__sectionThree {
        padding-left: 310px;
        padding-right: 310px;
        padding-top: 50px;

        &__gridbox {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 52px;

            &__left {
                h1 {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 46px;
                    color: #11002F;
                    text-align: left;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #5B5D5F;
                    text-align: left;
                    margin-top: 28px;
                }

                &__cols {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 32px;

                    div {
                        margin-top: 28px;
                        border-top: 1px solid #5B5D5F;

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #5B5D5F;
                        }

                        #aboutusButton {
                            margin-top: 28px;
                            width: 304px;
                            height: 57px;
                            font-size: 22px;
                        }
                    }
                }
            }

            &__right {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                position: relative;

                #about-string{
                    position: absolute;
                    bottom: -15%;
                    right: -20%;
                    z-index: 1;
                }

                &__imgbox {
                    margin-top: -50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: baseline;
                    align-items: center;
                    width: 313px;
                    height: fit-content;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
                    box-shadow: 0 0 8px gray;
                    background: white;
                    border-radius: 5px 5px 5px 5px;

                    animation: animate-imgbox 1s linear;

                    @keyframes animate-imgbox{
                        0%{
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }

                    img {
                        width: 100%;
                        height: 340px;
                    }

                    &__footer {
                        width: 100%;
                        background: #11002F;
                        color: white;
                        padding-left: 20px;
                        padding-top: 30px;
                        padding-bottom: 30px;
                        border-radius: 0 0 5px 5px;

                        span {
                            display: flex;
                            flex-direction: row;
                            justify-content: baseline;
                            align-items: center;
                            margin-top: 29px;

                            div {
                                width: 50px;
                                border: 1px solid white;
                            }

                            h1 {
                                font-family: 'Ubuntu';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 23px;
                                line-height: 26px;
                                margin-left: 16px;
                                color: #FFFFFF;
                            }
                        }

                        p {
                            margin-top: 20px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFAF5;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    &__sectionFour{
        padding-top: 70px;
        padding-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img{
            width: 1214px;
            height: 84px;
        }
    }
}

@media screen and (max-width: 1897px){
    .route__aboutus__sectionTwo p{
        width: 40%;
    }
}

@media screen and (max-width: 1842px){
    .route__aboutus__sectionTwo{
        padding-left: 250px;
        padding-right: 250px;
    }
    .route__aboutus__sectionThree{
        padding-left: 250px;
        padding-right: 250px;
    }
    .route__aboutus__sectionThree__gridbox__left__cols div #aboutusButton{
        width: 250px;
    }
}

@media screen and (max-width: 1700px){
    .route__aboutus__sectionThree{
        padding-left: 200px;
        padding-right: 200px;
    }
    .route__aboutus__sectionTwo{
        padding-left: 200px;
        padding-right: 200px;
    }
    .route__aboutus__sectionThree__gridbox__left__cols div #aboutusButton{
        width: 200px;
    }
}

@media screen and (max-width: 1540px){
    .route__aboutus__sectionTwo{
        padding-left: 100px;
        padding-right: 100px;
    }
    .route__aboutus__sectionThree{
        padding-left: 100px;
        padding-right: 100px;
    }
    .route__aboutus__sectionFour img{
        width: 90%;
    }
    .route__aboutus__sectionThree__gridbox__right #about-string{
        width: 180px;
        right: -10%;
    }
}

@media screen and (max-width: 1450px){
    .route__aboutus__sectionThree__gridbox__right #about-string{
        bottom: -5%;
    }
}

@media screen and (max-width: 1313px){
    .route__aboutus__sectionThree__gridbox{
        grid-template-columns: 1fr;
    }
    .route__aboutus__sectionThree__gridbox__right__imgbox{
        margin-top: -35%;
    }
    .route__aboutus__sectionThree__gridbox__right{
        order: 1;
        place-items: center;
    }
    .route__aboutus__sectionThree__gridbox__left{
        order: 2;
    }
    .route__aboutus__sectionTwo{
        padding-bottom: 175px;
    }
    .route__aboutus__sectionTwo p{
        width: 100%;
    }
    .route__aboutus__sectionThree__gridbox__right #about-string{
        right: 3%;
        bottom: -30%;
    }
}
@media screen and (max-width: 1010px){
    .route__aboutus__sectionThree__gridbox__left__cols div #aboutusButton{
        width: 100%;
    }
    .route__aboutus__sectionFour img{
        height: 100%;
    }
    .route__aboutus__sectionThree__gridbox__right__imgbox{
        width: 100%;
    }
    .route__aboutus__sectionThree__gridbox__right__imgbox img{
        height: 100%;
    }
    .route__aboutus__sectionThree__gridbox__right__imgbox{
        margin-top: -50%;
    }
    .route__aboutus__sectionThree{
        padding-left: 61px;
        padding-right: 61px;
    }
    .route__aboutus__sectionTwo{
        padding-left: 61px;
        padding-right: 61px;
    }
    .route__aboutus__sectionThree__gridbox__right #about-string{
        right: -5%;
    }
}

@media screen and (max-width: 860px){
    .route__aboutus__sectionThree__gridbox__right #about-string{
        right: -7%;
    }
}

@media screen and (max-width: 722px){
    .route__aboutus__sectionThree__gridbox__left h1{
        font-size: 30px;
    }
    .route__aboutus__sectionTwo h1{
        font-size: 30px;
    }

    .route__aboutus__sectionThree__gridbox__right__imgbox__footer span h1{
        font-size: 17px;
    }
}

@media screen and (max-width: 624px){
    .route__aboutus__sectionTwo{
        padding-left: 20px;
        padding-right: 20px;
    }
    .route__aboutus__sectionThree{
        padding-left: 20px;
        padding-right: 20px;
    }
    .route__aboutus__sectionOne h1{
        font-size: 40px;
    }
    .route__aboutus__sectionThree__gridbox__right #about-string{
        display: none;
    }
}

@media screen and (max-width: 518px){
    .route__aboutus__sectionThree__gridbox__right__imgbox__footer span h1{
        font-size: 12px;
    }
    .route__aboutus__sectionThree__gridbox__right__imgbox__footer p{
        font-size: 10px;
    }
    .route__aboutus__sectionOne p{
        width: 100%;
    }
    .route__aboutus__sectionThree__gridbox__left__cols{
        grid-template-columns: 1fr;
    }
    .route__aboutus__sectionThree__gridbox__left__cols div #aboutusButton{
        width: 238px;
        height: 53px;
        font-size: 18px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .route__aboutus__sectionThree__gridbox__right__imgbox{
        margin-top: -80%;
    }
}

@media screen and (max-width: 450px){
    .route__aboutus__sectionThree__gridbox__right__imgbox__footer span{
        margin-top: 0;
    }
    .route__aboutus__sectionThree__gridbox__right__imgbox__footer span div{
        width: 24px;
    }
    .route__aboutus__sectionFour{
        display: none;
    }
    .route__aboutus__sectionThree{
        padding-bottom: 50px;
    }
}