.component__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-left: 310px;
    padding-right: 310px;
    padding-top: 53px;

    #hamburger {
        display: none;
    }

    img {
        width: 202px;
        height: 50px;
        display: block;
        margin-left: 0;
        margin-right: auto;
    }

    span {
        margin-left: 40px;
        display: flex;
        align-items: center;

        img {
            margin-right: 15px;
            width: 30px;
            height: 30px;
        }

        p {
            margin-right: 15px;
            cursor: pointer;
            font-family: 'Roboto';
            color: #564B68;
        }
    }

    &__navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p{
            margin-right: 15px;
            cursor: pointer;
            font-family: 'Roboto';
            color: #564B68;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 1859px) {
    .component__header {
        padding-left: 230px;
        padding-right: 230px;
    }
}

@media screen and (max-width: 1550px) {
    .component__header {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 1230px) {
    .component__header {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;

        #hamburger {
            display: block;
            width: 27px;
            height: 18px;
            cursor: pointer;
        }

        &__navbar {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            background: #11002F;
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 36px;
            padding-bottom: 32px;
            overflow: hidden;
            animation: animateMenu 0.5s linear;

            @keyframes animateMenu{
                0%{
                    max-height: 0px;
                }
                100%{
                    max-height: 1000px;
                }
            }

            #nav-item, p {
                text-decoration: none;
                text-align: right;
                margin-right: 0;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                text-align: right;
                text-transform: uppercase;
                color: #FFFFFF;
                margin-top: 30px;
            }

            &__mobile {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                #close {
                    width: 20px;
                    height: 20px;
                    display: block;
                    margin-right: 0;
                    margin-left: auto;
                }
            }

            &__mobile--foo{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                margin-top: 162px;
                a{
                    margin-right: 27px;
                }
                img{
                    width: 24px;
                    height: 24px;
                    margin-right: 27px;
                    cursor: pointer;
                    filter:  brightness(0) invert(1);
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }

        span {
            margin-left: auto;
            margin-right: 0;
        }

        &__block {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

@media screen and (max-width: 574px){
    .component__header span{
        display: none;
    }
}