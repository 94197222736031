.route__home {
    max-width: 1920px;
    width: 100%;

    &__sectionOne {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 180px;
        padding-top: 81px;
        padding-left: 310px;
        padding-right: 310px;
        height: fit-content;
        padding-bottom: 81px;

        &__left {
            width: 580px;
            animation: animate-secOneHome .5s linear;

            @keyframes animate-secOneHome{
                0%{
                    margin-left: -100rem;
                }
                100%{
                    margin-left: 0;
                }
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;

                div {
                    margin-right: 21px;
                }
            }

            h1 {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 700;
                font-size: 60px;
                line-height: 69px;
                color: #11002F;
                text-align: left;
            }

            #bars {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                background: #5956E9;
                width: fit-content;
                color: white;
                font-family: 'Ubuntu';
                font-weight: 500;
                font-size: 22px;
                border-radius: 2px;

                p {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 25px;
                    color: #FFFFFF;
                    padding: 6px;
                    border-radius: 2px;
                    width: 143px;
                    height: 37px;
                }
            }

            #left-text {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: left;
                color: #564B68;
                margin-top: 56px;
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 57px;

                span {
                    display: flex;
                    flex-direction: row;

                    strong {
                        font-family: 'Ubuntu';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 25px;
                        color: #11002F;
                        cursor: pointer;
                    }

                    img {
                        margin-left: 30px;
                    }
                }
            }
        }

        &__right {
            div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0;
                padding-right: 310px;
                overflow: hidden;

                img {
                    width: 280px;
                    height: 280px;
                }

                #img1{
                    animation: animate-img1 1.5s linear;

                    @keyframes animate-img1{
                        0%{
                            margin-top: -100rem;
                        }
                        100%{
                            margin-top: 0;
                        }
                    }
                }

                #img2{
                    animation: animate-img2 1s linear;
                    display: block;
                    margin-left: auto;
                    margin-right: 0;
                    @keyframes animate-img2{
                        0%{
                            margin-right: -100rem;
                        }
                        100%{
                            margin-right: 0;
                        }
                    }
                }

                #img3{
                    animation: animate-img3 1s linear;

                    @keyframes animate-img3{
                        0%{
                            margin-top: -100rem;
                        }
                        100%{
                            margin-top: 0;
                        }
                    }
                }

                #img4{
                    animation: animate-img4 1.5s linear;
                    display: block;
                    margin-left: auto;
                    margin-right: 0;
                    @keyframes animate-img4{
                        0%{
                            margin-right: -100rem;
                        }
                        100%{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    &__sectionTwo {
        padding-bottom: 60px;

        &__mask {
            background: #11002F;
            width: 100%;
            height: 494px;
            z-index: -1;
        }

        &__gridbox {
            margin-top: -20%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            padding-left: 310px;
            padding-right: 310px;

            &__item--upper {
                margin-bottom: 60px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    color: #FFFFFF;

                    div {
                        margin-right: 21px;
                    }
                }

                h1 {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 46px;
                    color: #FFFFFF;
                    margin-top: 20px;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                    text-align: left;
                }
            }

            @mixin filter_transition {
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        animation: company-load .3s #{$i * .3}s forwards;
                    }
                }
            }
            @keyframes company-load {
                100% {
                    opacity: 1;
                }
            }

            .animate{
                @include filter_transition;
            }

            &__item {
                width: 640px;
                height: 554px;
                opacity: 0;

                img {
                    width: 640px;
                    height: 383px;
                }

                #f0 {
                    background: #11002F;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
                    border-radius: 5px;

                    span {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        font-family: 'Ubuntu';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 34px;
                        color: #FFFFFF;

                        div {
                            margin-right: 21px;
                        }
                    }

                    #foo-foo {
                        display: grid;
                        grid-template-columns: 80% 20%;
                        place-items: left;
                        margin-top: 20px;

                        img {
                            width: 58px;
                            height: 58px;
                            cursor: pointer;
                            margin-left: 30px;
                        }

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #FFFAF5;
                            text-align: left;
                        }
                    }
                }

                #f1 {
                    background: #F8F5F3;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
                    border-radius: 5px;

                    span {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        font-family: 'Ubuntu';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 34px;
                        color: #11002F;

                        div {
                            margin-right: 21px;
                        }
                    }

                    #foo-foo {
                        display: grid;
                        grid-template-columns: 80% 20%;
                        place-items: left;
                        margin-top: 20px;

                        img {
                            width: 58px;
                            height: 58px;
                            cursor: pointer;
                            margin-left: 30px;
                        }

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #11002F;
                            text-align: left;
                        }
                    }
                }

                &__foo {
                    padding: 30px;
                }
            }
        }
    }

    &__sectionThree {
        padding-top: 80px;
        padding-left: 310px;
        padding-right: 310px;
        padding-bottom: 70px;
        display: grid;
        grid-template-columns: 45% 55%;
        background: #F8F5F3;
        position: relative;

        #home-string {
            position: absolute;
            top: 18px;
            left: 0;
        }

        &__left {
            padding-right: 21px;

            h1 {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 46px;
                color: #11002F;
                text-align: left;
                margin-bottom: 19px;
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: #11002F;
                margin-bottom: 20px;

                div {
                    margin-right: 21px;
                }
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #564B68;
                text-align: left;
            }
        }

        &__right {
            &__form {
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 22px;

                div {
                    input {
                        width: 360px;
                        height: 50px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                        border-radius: 5px;
                        border: none;
                        outline: none;
                        margin-bottom: 16px;
                        padding: 20px;

                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #564B68;

                        &::placeholder {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #564B68;
                        }
                    }

                    textarea {
                        width: 360px;
                        height: 116px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                        border-radius: 5px;
                        padding: 20px;
                        border: none;
                        outline: none;

                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #564B68;

                        &::placeholder {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #564B68;
                        }
                    }

                    #contact-us-button-third.component__button {
                        margin-left: 0;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1859px) {
    .route__home__sectionTwo__gridbox {
        padding-left: 230px;
    }

    .route__home__sectionOne {
        gap: 100px;
        padding-left: 230px;
    }

    .route__home__sectionThree__right__form {
        width: fit-content;

        div {
            width: fit-content;
        }
    }

    .route__home__sectionThree__right__form div input {
        width: 100%;
    }

    .route__home__sectionThree__right__form div textarea {
        width: 100%;
    }
}

@media screen and (max-width: 1611px) {
    .route__home__sectionTwo__gridbox__item {
        width: 500px;
        height: fit-content;
    }

    .route__home__sectionTwo__gridbox__item img {
        width: 100%;
        height: 300px;
    }

    .route__home__sectionTwo__gridbox {
        padding-left: 310px;
    }
}

@media screen and (max-width: 1550px) {
    .route__home__sectionTwo__gridbox__item {
        width: 100%;
    }

    .route__home__sectionOne {
        padding-left: 100px;
        padding-right: 100px;
    }

    .route__home__sectionTwo__gridbox {
        padding-left: 100px;
        padding-right: 100px;
    }

    .route__home__sectionTwo__gridbox__item img {
        height: 383px;
    }

    .route__home__sectionThree {
        padding-left: 150px;
        padding-right: 150px;
        z-index: 1;
    }

    .route__home__sectionThree #home-string {
        z-index: 0;
        width: 200px;
    }

    .route__home__sectionOne__right div img {
        width: 200px;
        height: 200px;
    }
}

@media screen and (max-width: 1235px) {
    .route__home__sectionOne {
        grid-template-columns: 1fr;
        place-items: center;
    }

    .route__home__sectionOne__right div {
        margin-left: auto;
        margin-right: auto;
        padding-right: 0;
    }

    .route__home__sectionOne__right div img {
        width: 280px;
        height: 280px;
    }

    .route__home__sectionTwo__gridbox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 100px) repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-top: -35%;
    }

    #item1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    #item2 {
        grid-area: 2 / 1 / 3 / 2;
    }

    #item3 {
        grid-area: 3 / 1 / 4 / 2;
    }

    #item4 {
        grid-area: 3 / 2 / 4 / 3;
    }

    #item5 {
        grid-area: 4 / 1 / 5 / 2;
    }

    #item6 {
        grid-area: 4 / 2 / 5 / 3;
    }

    .route__home__sectionTwo__gridbox__item--upper span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }

    .route__home__sectionThree {
        grid-template-columns: 1fr;
        gap: 40px;
    }

    .route__home__sectionThree__right__form div #contact-us-button-third.component__button {
        margin-left: auto;
        margin-right: -30%;
    }
}

@media screen and (max-width: 1029px) {
    .route__home__sectionTwo__gridbox {
        padding-left: 20px;
        padding-right: 20px;
    }

    #item1,
    #item2 {
        margin-left: 60px;
    }

    .route__home__sectionTwo__gridbox__item--upper h1 {
        font-size: 35px;
    }

    .route__home__sectionOne__left {
        width: 100%;
    }

    .route__home__sectionOne__right div img {
        width: 380px;
        height: 380px;
    }

    .route__home__sectionOne__left__buttons {
        justify-content: flex-start;
    }

    .route__home__sectionOne__left__buttons span {
        margin-left: 40px;
    }
}

@media screen and (max-width: 880px) {
    .route__home__sectionOne__right div img {
        width: 100%;
        height: 100%;
    }

    .route__home__sectionTwo__gridbox {
        margin-top: -50%;
    }

    .route__home__sectionTwo__gridbox__item--upper span {
        text-align: left;
    }

    .route__home__sectionTwo__gridbox__item img {
        height: 300px;
    }

    .route__home__sectionTwo__gridbox__item #f1 #foo-foo img {
        margin-left: 5px;
    }

    .route__home__sectionTwo__gridbox__item #f0 #foo-foo img {
        margin-left: 5px;
    }
}

@media screen and (max-width: 852px) {
    .route__home__sectionTwo__gridbox__item img {
        height: 250px;
    }

    #item1,
    #item2 {
        margin-left: 0;
    }
}

@media screen and (max-width: 802px) {
    .route__home__sectionThree {
        padding-left: 20px;
        padding-right: 20px;
    }

    .route__home__sectionThree__left {
        margin-left: 100px;
    }

    .route__home__sectionThree #home-string {
        width: 183px;
    }

    .route__home__sectionThree__right__form div {
        width: 90%;
    }

    .route__home__sectionThree__right__form {
        margin-left: auto;
        margin-right: auto;
    }

    .route__home__sectionThree__right__form div #contact-us-button-third.component__button {
        margin-right: -50%;
    }
}

@media screen and (max-width: 735px) {
    .route__home__sectionOne__left__buttons {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            margin-top: 31px;
        }
    }

    .route__home__sectionTwo__gridbox {
        display: flex;
        flex-direction: column;
    }

    .route__home__sectionTwo__gridbox__item {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    .route__home__sectionTwo__gridbox__item--upper {
        margin-bottom: 10px;
    }

    .route__home__sectionTwo__gridbox {
        margin-top: -65%;
    }

    .route__home__sectionTwo__gridbox__item img {
        height: 300px;
    }

    .route__home__sectionThree__right__form {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .div1 {
            grid-area: 1 / 1 / 2 / 2;
        }

        .div2 {
            grid-area: 2 / 1 / 3 / 2;
        }
    }

    .route__home__sectionThree__right__form div #contact-us-button-third.component__button {
        margin-right: auto;
        margin-left: 30%;
        position: absolute;
        bottom: -15%;
    }

    .route__home__sectionThree__right__form div {
        width: 100%;
    }

    .route__home__sectionOne__right div {
        width: 100%;
    }

    .route__home__sectionOne__left {
        width: 80%;
    }

    .route__home__sectionOne__left h1 {
        font-size: 40px;
    }

    .route__home__sectionOne__left #bars {
        width: 80%;
    }

    .route__home__sectionOne__left #bars p {
        width: fit-content;
        font-size: 18px;
    }

    .route__home__sectionOne__left__buttons span {
        margin-left: 0;
    }
}

@media screen and (max-width: 574px) {
    .route__home__sectionTwo__gridbox {
        margin-top: -80%;
    }

    .route__home__sectionOne {
        padding-left: 20px;
        padding-right: 20px;
    }

    .route__home__sectionTwo__gridbox__item--upper h1 {
        text-align: left;
    }

    .route__home__sectionTwo__gridbox__item {
        width: 100%;
    }

    .route__home__sectionTwo__gridbox__item img {
        height: 400px;
    }

    .route__home__sectionThree__right__form div #contact-us-button-third.component__button {
        margin-left: 23%;
    }
}

@media screen and (max-width: 461px) {
    .route__home__sectionTwo__gridbox__item img {
        height: 250px;
    }

    .route__home__sectionThree #home-string {
        top: -55px;
        width: 111px;
        height: 118px;
    }

    .route__home__sectionThree__left {
        margin-left: 20px;
    }

    .route__home__sectionOne__left {
        width: 100%;
    }

    .route__home__sectionThree__right__form div #contact-us-button-third.component__button {
        margin-left: 20%;
    }

    .route__home__sectionTwo__gridbox {
        margin-top: -100%;
    }
}

@media screen and (max-width: 414px) {
    .route__home__sectionThree__right__form div #contact-us-button-third.component__button {
        margin-left: 15%;
    }

    .route__home__sectionOne__right div {
        width: 80%;
    }

    .route__home__sectionOne__left {
        width: 100%;
    }

    .route__home__sectionOne__left #left-text {
        width: 100%;
    }

    .route__home__sectionOne__left #bars p {
        width: 110px;
        margin-right: 2px;
    }
}